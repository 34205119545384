<template>
  <div class="footer-wrap  ">
    <div class="main-container container">
      <div class="top-wrap">
        <div class="common-wrap">
          <p class="title font18">公司介绍</p>
          <p class="item font14" @click="jump('index')">查看首页</p>
          <p class="item font14" @click="jump('about')">关于我们</p>
        </div>
        <div class="common-wrap product-common-wrap">
          <p class="title font18">产品介绍</p>
          <!-- <p class="item font14" @click="jump('product')">系统开源</p> -->
          <p class="item font14" @click="jump('product', 2)">广告变现</p>
          <p class="item font14" @click="jump('product', 3)">聚合管理</p>
          <p class="item font14" @click="jump('product', 4)">福利中心</p>
          <p class="item font14" @click="jump('solution')">高频广告位解决方案</p>
          <p class="item font14" @click="jump('solution', 2)">精细化售卖解决方案</p>
          <p class="item font14" @click="jump('solution', 3)">弱网环境解决方案</p>
          <!--<p class="item font14"  @click="jump('business')">业务场景</p>-->
          <!--<p class="item font14"  @click="jump('security')">安全透明</p>-->
        </div>
        <div class="common-wrap">
          <p class="title font18">解决方案</p>
          <p class="item font14" @click="jump('client-case')">精选案例</p>
        </div>
        <div class="common-wrap">
          <p class="title font18">关注我们</p>
          <p class="item font14">
            <el-popover
              placement="right"
              width="130"
              popper-class="follow-popover"
              trigger="hover">
              <span slot="reference" class="qrTxt">服务号</span>
              <!-- 弹出框内容 -->
              <div class="solution" ref="solution">
                <img src="../assets/image/index/fwhQRcode.png" alt="服务号二维码">
              </div>
            </el-popover>
          </p>
          <!-- <p class="item font14">
            <el-popover
              placement="right"
              width="130"
              popper-class="follow-popover"
              trigger="hover">
              <span slot="reference" class="qrTxt">订阅号</span>
              <div class="solution" ref="solution">
                <img src="../assets/image/index/dyhQRcode.png" alt="订阅号二维码">
              </div>
            </el-popover>
          </p> -->
          <!-- <p class="item font14">
            <el-popover
              placement="right"
              width="130"
              popper-class="follow-popover"
              trigger="hover">
              <span slot="reference" class="qrTxt">知乎</span>
              <div class="solution" ref="solution">
                <img src="../assets/image/index/zhQRcode.png" alt="知乎二维码">
              </div>
            </el-popover>
          </p> -->
          <!-- <p class="item font14">
            <el-popover
              placement="right"
              width="130"
              popper-class="follow-popover"
              trigger="hover">
              <span slot="reference" class="qrTxt">微博</span>
              <div class="solution" ref="solution">
                <img src="../assets/image/index/wbQRcode.png" alt="微博二维码">
              </div>
            </el-popover>
          </p> -->
        </div>
        <div class="common-wrap contact">
          <p class="title font18">联系我们</p>
          <div class="contact-item">
            <div class="content-info">
              <!-- <p class="info-item">
                <span class="key">电子邮箱：</span>
                <span class="value">marketing@ad-scope.com.cn</span>
              </p> -->
              <p class="info-item">
                <span class="key">公关新闻：</span>
                <span class="value">zhangmin@hubcloud.com.cn</span>
                <br>
                <span class="value" :style="{ 'padding-left': '70px' }">15801774389</span>
              </p>
              <p class="info-item">
                <span class="key">业务咨询：</span>
                <span class="value">18658296496</span>
              </p>
              <!--<p class="info-item">-->
                <!--<span class="key">办公地址：</span>-->
                <!--<span class="value">总部-北京市朝阳区远洋国际中心D座1306</span>-->
              <!--</p>-->
              <!--<p class="info-item">-->
                <!--<span class="key">办公地址：</span>-->
                <!--<span class="value">上海市徐汇区桂林路402号诚达创意产业园301</span>-->
              <!--</p>-->
            </div>
          </div>
        </div>
      </div>
      <div class="buttom-wrap">
        <span class="tips" :style="{ 'width': '100%', 'text-align': 'center' }">All Rights Reserved. <span class="tips-number" @click="handleOpenNewPage">{{ IcpNumber }}</span></span>
        <!-- <span class="tips">All Rights Reserved. 沪ICP备<span class="tips-number" @click="handleOpenNewPage">17036269号.</span></span> -->
        <!-- <span class="tips"><span class="bgSpan">沪公网安备</span><span class="tips-number" @click="handleOpenNewPage">31010402006552</span></span> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isHome: null,           // 是否是首页
      page: '',               // 当前页面名称
    };
  },
  computed: {
    IcpNumber() {
      if (location.host.includes('ad-scope.com.cn')) return '沪ICP备20011467号-7';
      if (location.host.includes('adscope.com.cn')) return '沪ICP备20011467号-6';
      if (location.host.includes('adscope.cn')) return '沪ICP备20011467号-4';
      if (location.host.includes('adscope.xyz')) return '沪ICP备20011467号-5';
      if (location.host.includes('beizi.biz')) return '沪ICP备20011467号-1';
      if (location.host.includes('beizi.info')) return '沪ICP备20011467号-3';
      if (location.host.includes('beizi.online')) return '沪ICP备20011467号-2';
      return '沪ICP备20011467号';
    }
  },
  mounted() {
    let path = window.location.hash.split('/');
    let end = path[path.length-1];
    this.page = end;
    // 因为首页会有其他页面进来后携带的参数  单独判断
    if(window.location.hash.includes('index')) {
      this.isHome = true;
    }else {
      this.isHome = false;
    }
  },
  methods: {
    // 跳转页面
    jump(path, flag='') {
      this.$router.push({ name: path, hash: flag ? '#page=' + flag : '' });
    },
    // 点击备案号打开新页面
    handleOpenNewPage() {
      window.open('https://beian.miit.gov.cn/', "recordNumber");
    }
  },
};
</script>
<style lang="scss">
  .footer-wrap {
    width: 100%;
    padding-bottom: 20px;
    background-image: linear-gradient(#2d2d32 ,#222226);
    color: #B8B8BF;
    .container {
      height: 100%;
      padding-top: 50px;
      box-sizing: border-box;
      .top-wrap {
        display: flex;
        justify-content: flex-start;
        /* 公共布局 */
        .common-wrap {
          width: 25%;
          margin-right: 50px;
          .title {
            border-bottom: 1px solid;
            border-bottom-color: rgba(140,140,140,.25);
            height: 40px;
            line-height: 30px;
            margin-bottom: 10px;
            font-weight: 700;
            color: #B8B8C0;
          }
          .item {
            height: 40px;
            line-height: 30px;
            cursor: pointer;
            color: #fff;
          }
          .qrTxt {
            display: inline-block;
            width: 45px;
          }
        }
        .product-common-wrap{
          width: 30%;
        }
        .common-wrap:last-child {
          margin-right: 0px;
          margin-left: 120px;
        }
        /* 联系我们 */
        .contact {
          width: 100%;
          .content-info {
            color: #fff;
            flex: 1;
            height: 100%;
            font-size: 14px;
            float: left;
            .info-item {
              width: 100%;
              line-height: 34px;
              .key {
                display: inline-block;
                vertical-align: middle;
                width: 70px;
              }
              .value {
                display: inline-block;
                vertical-align: middle;
              }
            }
          }
        }
      }
      /* 备案信息 */
      .buttom-wrap {
        /*text-align: center;*/
        font-size: 14px;
        padding-top: 44px;
        box-sizing: border-box;
        display: flex;
        .tips {
          display: inline-block;
          vertical-align: middle;
          width: 50%;
          .tips-number {
            cursor: pointer;
            padding-left: 8px;
          }
          .bgSpan{
            padding-left: 20px;
            background: url("../assets/image/authentication.png") left no-repeat;
            background-size: 18px 20px;
          }
        }
        .tips:first-child{
          text-align: right;
        }
        .tips:last-child{
          text-align: left;
        }
        .icon {
          display: inline-block;
          width: 18px;
          height: 20px;
          vertical-align: middle;
          margin: 0 10px 0 40px;
        }
      }
    }
  }
  .follow-popover{
    min-width: auto !important;
    padding: 0px !important;
    .solution img {
      width: 130px;
    }
  }
</style>
